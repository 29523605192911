// 端口配置文件
const port = 80;
const BASEURL = 'http://test.uzhikang.com/apiylxb';
const BASEURLIMG = 'https://adminswbd.obcin.com/wapimg/';

const API_LIST = {
	// 侧边栏数据
	sidebar: BASEURL + '/Index/sidebar',
	// 首页数据
	index: BASEURL + '/Index/index',
	// 上方分类栏
	nav: BASEURL + '/Index/nav',
	// 初始化列表
	list: BASEURL + '/Index/list',
	// 切换
	cateListData: BASEURL + '/Index/cateListData',
	// 详细
	content: BASEURL + '/Index/content',
	// 搜索
	select: BASEURL + '/Select/select',
	
}
module.exports = API_LIST;