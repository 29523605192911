<template>
	<div class="footer">
		<!-- <div class="nums">
			<p>互联网药品信息服务资格证书：(粤)-经营性-2021-0467</p>
			<a href="https://beian.miit.gov.cn/">ICP备案号：粤ICP备2021111919号</a>
			<p>版权所有深圳市粤智康科技有限公司</p>
			
		</div>
		<div class="erweima">
			<div>
				<img :src="list.qrcode" alt="">
				<span>公众号二维码</span>
			</div>
			
			<div>
				<span>深圳市粤智康科技有限公司</span>
				<span>地址：深圳市宝安区西乡街道渔业社区名优采购中心B座1区101</span>
				<span>联系电话：0755-23149439</span>
			</div>
			
		</div> -->
		<div class="top">
			<div class="lianxi">
				<h4>联系我们</h4>
				<div>
					<p>大连一路向北网络技术有限公司</p>
					<p>地址：大连市西岗区长春路341号A区210室</p>
					<p>联系电话：0411-8248-8100</p>
				</div>
			</div>
			<div class="gongzhong">
				<h4>微信公众号</h4>
				<img src="../assets/ad1954ff10276785e61c1f7f42503ad.jpg" alt="">
			</div>
			<div class="banquan">
				<h4>版权信息</h4>
				<div>
					<p>互联网药品信息服务资格证书：(辽)-经营性-2022-0028</p>
					<p>ICP备案号：辽ICP备18017894号-2</p>
					<p style="display: flex;flex-direction: row;align-items: center;">
						<img style="width: 20px;height: 20px;margin: 0 5px 0 0;" src="../assets/bei.png" alt="">
						<a href="https://beian.mps.gov.cn/#/query/webSearch?code=21020302000559" rel="noreferrer" target="_blank">辽公网安备21020302000559</a>
					</p>	
				</div>
			</div>
		</div>
		
		<p class="bottom">特别声明：本站信息仅供参考_不能作为诊断及医疗的依据 ┊ 本站如有转载或引用文章涉及版权问题_请速与我们联系</p>
	</div>
</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '@/list.config.js';
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import qs from 'qs'
	export default {
	
		name: "Zi",
		props: {
	
		},
		data() {
			return {
				list: [],
				listData: [],
				nav_id: '',
				page: 1,
				chooseId: 0,
				show: false,
				counts: 0, // 页数
				ind: 0,
				num: 0,
				hidden: true,
			}
	
		},
		watch: {
			
		},
		computed: {
	
		},
	
		created() {
			const that = this;
			
			axios.post(API_LIST.footer, {
					
				}, {
					headers: {
						// 'Content-Type': 'application/x-www-form-urlencoded'
						'Content-Type': 'application/json'
					} //加上这个x
				})
				.then(_d => {
					console.log(_d.data)
					if (_d.data.status == 200) {
						that.list = _d.data.list;
					} else if (_d.data.status == 409) {
	
					}
	
	
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		mounted() {
	
		},
	
		methods: {
			
		},
	}
	
</script>

<style scoped="scoped" lang="scss">
	.footer{
		margin: 50px 0 0 0;
		background-color: #F7F9FF;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 20px 0;
		.top{
			display: flex;
			flex-direction: row;
			.lianxi{
				display: flex;
				flex-direction: column;
				text-align: center;
				h4{
					font-size: 20px;
					color: #000;
				}
				p{
					color: #999999;
					font-size: 16px;
				}
				div{
					text-align: left;
					height: 160px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					box-sizing: border-box;
					padding: 20px;
				}
			}
			.gongzhong{
				display: flex;
				flex-direction: column;
				text-align: center;
				border-left: 2px solid #e6e6e6;
				border-right: 2px solid #e6e6e6;
				width: 280px;
				h4{
					font-size: 20px;
					color: #000;
				}
				img{
					margin: 0 auto;
					width: 160px;
				}
			}
			.banquan{
				display: flex;
				flex-direction: column;
				text-align: center;
				h4{
					font-size: 20px;
					color: #000;
				}
				p{
					color: #999999;
					font-size: 16px;
					
				}
				div{
					text-align: left;
					height: 160px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					box-sizing: border-box;
					padding: 20px;
				}
			}
		}
		.bottom{
			margin: 20px 0;
			font-size: 12px;
			color: #999999;
			text-align: center;
		}
	}
</style>
