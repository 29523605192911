// 端口配置文件
const port = 80;
const BASEURL = 'https://gwadmin.lyunjian.com/apiylxb';
const BASEURLIMG = 'https://gwadmin.lyunjian.com/wapimg/';

const API_LIST = {
	// 侧边栏数据
	sidebar: BASEURL + '/Index/sidebar',
	// 首页数据
	index: BASEURL + '/Index/index',
	// 上方分类栏
	nav: BASEURL + '/Index/nav',
	// 初始化列表
	list: BASEURL + '/Index/list',
	// 切换
	cateListData: BASEURL + '/Index/cateListData',
	// 详细
	content: BASEURL + '/Index/content',
	// 搜索
	select: BASEURL + '/Select/select',
	// 底部
	footer: BASEURL + '/Index/footer',
	// 
	banner_content: BASEURL + '/Index/banner_content',
	// 医院列表
	hospitalList: BASEURL + '/Index/hospitalList',
	// 药店信息
	drugList: BASEURL + '/Index/drugList',
	// 药品列表
	Druginfo_list: BASEURL + '/Druginfo/list',
	// 分类列表
	drug_list: BASEURL + '/Druginfo/drug_2_list',
	// 药品详情
	info: BASEURL + '/Druginfo/info',
	// 轮播
	banner:BASEURL + '/Index/banner',
	// 首页1接口
	article1:BASEURL + '/Index/article1',
	// 首页2接口
	article2:BASEURL + '/Index/article2',
	// 首页3接口
	article3:BASEURL + '/Index/article3',
	// 首页2接口
	article4:BASEURL + '/Index/article4',
}
module.exports = API_LIST;