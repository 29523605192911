<template>
	<div class="listwork">
		<div :class="{chang:changBol}">
			<div class="bg">
				<p @click='chooseBtn(item.id)' :class="['listOne',{choose:choose_id == item.id}]" v-for='item in list'>{{item.name}}</p>
			</div>
		</div>
	</div>
</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '@/list.config.js';
	import qs from 'qs'
	export default {

		name: "liskwork",

		props: {
			
		},
		data() {
			return {
				list: [],
				choose_id: 1,
				changBol:false, // 判定下滑展示的样式
			}

		},
		watch: {

		},
		created() {
			const that = this;
			if(this.$route.query.id){
				that.choose_id = this.$route.query.id
			}
			
			axios.post(API_LIST.nav, {}, {
					headers: {
						// 'Content-Type': 'application/x-www-form-urlencoded'
						'Content-Type': 'application/json'
					} //加上这个x
				})
				.then(_d => {
					console.log(_d.data)
					if (_d.data.status == 200) {
						// console.log(_d)
						that.list = _d.data.list
					} else if (_d.data.status == 409) {
						
					}
					
								
				})
				.catch(function(error) {
					console.log(error);
				});
		}, 
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
		},
		methods: {
			// 点击功能栏
			chooseBtn(e) {
				const that = this;
				that.$data.choose_id = e
				if(e == 1){
					this.$router.push({path:'/'})
				}else{
					if(e == 4){
						this.$router.push({path:'/Drug',query:{id:that.$data.choose_id}})
					}else if(e == 6){
						this.$router.push({path:'/Pharmacy',query:{id:that.$data.choose_id}})
					}else if(e == 8){
						this.$router.push({path:'/Hospital',query:{id:that.$data.choose_id}})
					}else{
						this.$router.push({path:'/List',query:{id:that.$data.choose_id}})
					}
					
				}
				
			},
			handleScroll() {
				
				let scrollY = window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				let scrollObj = document.querySelector(".listwork");
				//scrollObj 这个是下图右边答题卡的div
				if (scrollY > 40) {
					//这个358就是上面的距离
					scrollObj.style.position = "fixed";
					scrollObj.style.zIndex = 10000000;
					scrollObj.style.right = "0";
					scrollObj.style.top = "90px";
					this.changBol = true;
					
				} else {
					scrollObj.style.position = "static";
					scrollObj.style.marginTop = "0";
					this.changBol = false;
				}
			}
		},
		destroyed() {
			//销毁监听
			window.removeEventListener("scroll", this.handleScroll);
		},
	};
</script>

<style scoped="scoped" lang="scss">
	.listwork {
		width: 100%;
		height: 60px;
		background-color: #0078F0;
		margin: 0px 0 10px 0;
	}
	.chang{
		width: 1330px;
		margin: 0 auto;
	}

	.bg {
		width: 100%;
		padding: 0 20px;
		box-sizing: border-box;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 60px;

		.listOne {
			font-size: 18px;
			color: #fff !important;
			height: 60px;
			line-height: 60px;
			padding: 0 20px;
		}

		.listOne:hover {
			cursor: pointer;
			background-color: #43A1FF !important;
		}
	}
	

	.choose {
		background-color: #43A1FF !important;
	}
</style>
