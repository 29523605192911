<template>
	<div class="bg">
		<div class="nei">
			<img class="logo" src="../assets/logo.png" alt="">
			<div class="searchInp">
				<input type="text" placeholder="请输入关键词" v-model="val">
				<button @click="search"></button>
			</div>
		</div>
		
	</div>
	
</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '../../list.config.js';
	import qs from 'qs'
	export default {
		
		name: "Search",
		data() {
			return {
				choose_text: '数据库',
				val: '',
				bools:this.bool
			}

		},
		props: {
			bool:Boolean,
		},
		watch: {

		},
		created() {
			console.log(this.bool)
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
		},
		methods: {
			search() {
				const that = this;
				console.log(that.val)
				this.$router.push({
					path:'/SearchList',
					query:{
						val:that.val,
					}
					
				})
			},
			handleScroll() {
				let scrollY = window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				let scrollObj = document.querySelector(".bg");
				//scrollObj 这个是下图右边答题卡的div
				if (scrollY > 40) {
					//这个358就是上面的距离
					scrollObj.style.position = "fixed";
					scrollObj.style.zIndex = 10000000;
					scrollObj.style.width = '1320px';
					scrollObj.style.right = "0";
					scrollObj.style.left = "0";
					scrollObj.style.top = "0px";
					scrollObj.style.margin = "auto";
					
				} else {
					scrollObj.style.position = "static";
					scrollObj.style.marginTop = "0";
				}
			},
		},
		destroyed() {
			//销毁监听
			window.removeEventListener("scroll", this.handleScroll);
		},
	};
</script>

<style scoped lang="scss">
	.bg{
		background-color: #fff;
		// justify-content: center;
		// width: 100%;
		// height: ;
		.nei{
			height: 50px;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 20px 0;
		}
		.logo{
			width: 181px;
			// height: 66px;
			
		}
		.searchInp{
			position: relative;
			margin: 0 0 0 400px;
			input{
				width: 300px;
				height: 40px;
				background-color: #F6F7FA;
				border: 1px solid #eee;
				border-radius: 50px;
				padding: 0 0 0 10px;
				box-sizing: border-box;
				font-size: 14px;
			}
			button{
				width: 60px;
				height: 42px;
				position: absolute;
				top: 0;
				right: 0;
				background-color: #0078F0;
				border-radius: 50px;
				background-image: url(../assets/index_2.png);
				background-size: 22px 22px;
				background-position: 15px 9px;
				background-repeat: no-repeat;
				
			}
		}
	}
	
	.Search {
		width: 1200px;
		
		display: flex;
		flex-direction: row;
		// background-color: #000;
		.img {
			display: flex;
			flex-direction: row;
			align-items: center;

			img {
				margin: 0 10px 0 0;
			}
		}

		.search_box {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin: 0 0 0 200px;

			.text {
				padding: 0 20px;
				height: 28px;
				background-color: #EDEDED;
				text-align: center;
				line-height: 28px;
				color: #000;
				font-size: 10px;
				position: relative;

			}

			// .text::after {
			// 	content: '';
			// 	position: absolute;
			// 	right: 7px;
			// 	top: 0;
			// 	bottom: 0;
			// 	margin: auto;
			// 	width: 0;
			// 	height: 0;
			// 	border-left: 5px solid transparent;
			// 	border-right: 5px solid transparent;
			// 	border-top: 5px solid #000;

			// }

			input {
				width: 277px;
				border: 1px solid #EDEDED;
				height: 26px;
				font-size: 12px;
				padding: 0 0 0 5px;
			}

			.btn {
				height: 28px;
				padding: 0 20px;
				text-align: center;
				line-height: 28px;
				background-color: #39A49B;
				font-size: 12px;
				color: #fff;
			}
		}

	}
</style>
