<template>
	<div class="helloWorld">
		<div class="hello">
			<Information />
		</div>
		
	</div>
</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage,
		
	} from 'element-plus'
	import Information from "./Information.vue";



	export default {
		name: "HelloWorld",
		props: {
			
		},
		components: {
			Information
		},
		data() {
			return {
				
			}

		},
		watch: {

			
		},
		created() {
			
		},
		mounted() {
			
		},
		methods: {
		
		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.helloWorld{
		width: 100%;
	}
	.hello {
		width: 100%;
		// height: 100px;
		// background-color: #0f1;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	
</style>
