<template>
	<div class="info">
		<div class="OneBox">
			<div class="hotNew">
				<div>
					<img src="../assets/index_1.png" alt="">
					<span>热门新闻</span>
				</div>
				<ul>
					<li @click='detail(item.id)' v-for="(item,index) in infoOneList1"><span
							:class="[{color1:index == 0,color2:index == 1,color3:index == 2}]">{{index+1}}</span>{{item.title}}
					</li>
				</ul>
			</div>
			<div class="right">
				<div class="top">
					<div class="lunbo">
						<el-carousel>
							<el-carousel-item v-for="item in banner" :key="item" @click='detail2(item.id)'>
								<img style="width: 480px;height: 250px;" :src="item.img" alt="">
								<p class="text">{{item.title}}</p>
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="productNew">
						<div class="proTop">
							<span>产品新闻</span>
							<span class="more">更多>></span>
						</div>
						<ul>
							<li @click='detail(item.id)' v-for="(item,index) in infoOneList2" :class="{bor:index == 0}">
								<img :src="item.image" alt="">
								<div class="text">
									<p>{{item.title}}</p>
									<span>发布时间:{{item.addtime}}</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="bottom">
					<img @click="ind_1" src="../assets/index_1.jpg" alt="">
					<img @click="ind_2" src="../assets/index_2.jpg" alt="">
					<img @click="ind_3" src="../assets/index_3.jpg" alt="">
					<img @click="ind_4" src="../assets/index_4.jpg" alt="">
				</div>
			</div>
		</div>
		<div class="TwoBox">
			<div class="left">
				<div class="top">
					<div class="one">
						<div class="title">
							<span>政策法规</span>
							<span class="more">更多>></span>
						</div>
						<ul>
							<li @click='detail(item.id)' v-for="(item,index) in infoOneList3">
								<div v-if="index == 0">
									<img :src="item.image" alt="">
									<p>{{item.title}}</p>
								</div>
								<p class="liP" v-else>{{item.title}}</p>
							</li>
						</ul>
					</div>
					<div class="two">
						<div class="title">
							<span class="titles">药企新闻</span>
							<span class="more">更多>></span>
						</div>
						<ul>
							<li @click='detail(item.id)' v-for="(item,index) in infoOneList4">
								<img :src="item.image" alt="">
								<div>
									<p>{{item.title}}</p>
									<span>发布时间:{{item.addtime}} 浏览:{{item.click}}</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="three">
					<div class="title">
						<span class="titles">健康新闻</span>
						<span class="more">更多>></span>
					</div>
					<ul>
						<li @click='detail(item.id)' v-for="(item,index) in infoOneList5">
							<img :src="item.image" alt="">
							<div>
								<p>{{item.title}}</p>
								<p class="summary">{{item.summary}}</p>
								<span>发布时间:{{item.addtime}} 浏览:{{item.click}}</span>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="right">
				<div class="one">
					<div class="title">
						<span class="titles">行业新闻</span>
						<span class="more">更多>></span>
					</div>
					<ul>
						<li @click='detail(item.id)' v-for="(item,index) in infoOneList6">
							<img :src="item.image" alt="">
							<div>
								<p>{{item.title}}</p>
								<span>发布时间:{{item.addtime}} 浏览:{{item.click}}</span>
							</div>
						</li>
					</ul>
				</div>
				<div class="two">
					<div class="title">
						<span class="titles">产品维价</span>
						<span class="more">更多>></span>
					</div>
					<ul>
						<li @click='detail(item.id)' v-for="(item,index) in infoOneList7">
							<p><span>{{index+1}}</span>{{item.title}}</p>
						</li>
					</ul>
				</div>
				<div class="three">
					<div class="title">
						<span class="titles">医药人才</span>
						<span class="more">更多>></span>
					</div>
					<ul>
						<li @click='detail(item.id)' v-for="(item,index) in infoOneList9">
							<img :src="item.image" alt="">
							<div>
								<p>{{item.title}}</p>
								<span>发布时间:{{item.addtime}} 浏览:{{item.click}}</span>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '@/list.config.js';
	import qs from 'qs'

	export default {
		name: "HelloWorld",
		props: {

		},
		data() {
			return {
				list: '',
				banner:'',
				infoOneList1: [],
				infoOneList2: [],
				infoOneList3: [],
				infoOneList4: [],
				infoOneList5: [],
				infoOneList6: [],
				infoOneList7: [],
				infoOneList8: [],
				infoOneList9: [],
				infoOneChoose1: 0,
				infoOneChoose2: 0,
				infoOneChoose3: 0,
				infoOneChoose4: 0,
				infoOneChoose5: 0,
				infoOneChoose6: 0,
			}

		},
		watch: {


		},
		beforeCreate() {

		},
		created() {
			const that = this;
			axios.post(API_LIST.article1, {}, {
					headers: {
						// 'Content-Type': 'application/x-www-form-urlencoded'
						'Content-Type': 'application/json'
					} //加上这个x
				})
				.then(_d => {
					console.log(_d.data)
					if (_d.data.status == 200) {
						console.log('.....', _d.data)
						that.list = _d.data.list
						that.infoOneList1 = (_d.data.list.article1)[0].article

						console.log(that.infoOneList4)
					} else if (_d.data.status == 409) {

					}


				})
				.catch(function(error) {
					console.log(error);
				});
			axios.post(API_LIST.article2, {}, {
					headers: {
						// 'Content-Type': 'application/x-www-form-urlencoded'
						'Content-Type': 'application/json'
					} //加上这个x
				})
				.then(_d => {
					console.log(_d.data)
					if (_d.data.status == 200) {
						console.log('.....', _d.data)
						that.list = _d.data.list
						// that.infoOneList1 = (_d.data.list.article1)[0].article
						that.infoOneList2 = (_d.data.list.article2)[3].article
						that.infoOneList2 = that.infoOneList2.slice(0, 2)
						console.log(that.infoOneList4)
					} else if (_d.data.status == 409) {
			
					}
			
			
				})
				.catch(function(error) {
					console.log(error);
				});
			axios.post(API_LIST.article3, {}, {
					headers: {
						// 'Content-Type': 'application/x-www-form-urlencoded'
						'Content-Type': 'application/json'
					} //加上这个x
				})
				.then(_d => {
					console.log(_d.data)
					if (_d.data.status == 200) {
						console.log('.....', _d.data)
						that.list = _d.data.list
						that.infoOneList3 = (_d.data.list.article3)[0].article
					} else if (_d.data.status == 409) {
			
					}
			
			
				})
				.catch(function(error) {
					console.log(error);
				});
			axios.post(API_LIST.article4, {}, {
					headers: {
						// 'Content-Type': 'application/x-www-form-urlencoded'
						'Content-Type': 'application/json'
					} //加上这个x
				})
				.then(_d => {
					console.log(_d.data)
					if (_d.data.status == 200) {
						console.log('.....', _d.data)
						that.list = _d.data.list
						that.infoOneList4 = (_d.data.list.article4)[0].article
						that.infoOneList4 = that.infoOneList4.slice(0, 3)
			
						console.log(that.infoOneList4)
					} else if (_d.data.status == 409) {
			
					}
			
			
				})
				.catch(function(error) {
					console.log(error);
				});
			axios.post(API_LIST.index, {}, {
					headers: {
						// 'Content-Type': 'application/x-www-form-urlencoded'
						'Content-Type': 'application/json'
					} //加上这个x
				})
				.then(_d => {
					console.log(_d.data)
					if (_d.data.status == 200) {
						console.log('.....', _d.data)
						that.list = _d.data.list
						that.infoOneList5 = (_d.data.list.article5)[0].article
						that.infoOneList6 = (_d.data.list.article6)[0].article
						that.infoOneList7 = (_d.data.list.article7)[0].article
						that.infoOneList8 = (_d.data.list.article8)[0].article
						that.infoOneList9 = (_d.data.list.article9)[0].article
						that.infoOneList6 = that.infoOneList6.slice(0, 3)
						that.infoOneList9 = that.infoOneList9.slice(0, 3)
			
						console.log(that.infoOneList4)
					} else if (_d.data.status == 409) {
			
					}
			
			
				})
				.catch(function(error) {
					console.log(error);
				});
			axios.post(API_LIST.banner, {}, {
					headers: {
						// 'Content-Type': 'application/x-www-form-urlencoded'
						'Content-Type': 'application/json'
					} //加上这个x
				})
				.then(_d => {
					console.log('....', _d.data)
					if (_d.data.status == 200) {
						that.banner = _d.data.list
					}
				})
		},

		mounted() {

		},
		methods: {
			ind_1() {
				this.$router.push({
					path: '/List',
					query: {
						id: 3,
					}
				})
			},
			ind_2() {
				this.$router.push({
					path: '/Hospital',
					query: {
						id: 8,
					}
				})
			},
			ind_3() {
				this.$router.push({
					path: '/Drug',
					query: {
						id: 4,
					}
				})
			},
			ind_4() {
				this.$router.push({
					path: '/List',
					query: {
						id: 5,
					}
				})
			},
			// 选择1
			choose_1(_id) {
				const that = this;
				that.infoOneChoose1 = _id;
				that.infoOneList1 = (that.list.article1)[_id].article
			},
			// 选择2
			choose_2(_id) {
				const that = this;
				that.infoOneChoose2 = _id;
				that.infoOneList2 = (that.list.article2)[_id].article
			},
			// 点击四个块
			lists(_id) {
				console.log(this)
				this.$router.push({
					path: '/List',
					query: {
						id: _id
					}
				})
			},
			detail(_id) {
				this.$router.push({
					path: '/Detail',
					query: {
						id: _id,
					}
				})
			},
			// 详情
			detail2(_id) {
				this.$router.push({
					path: '/Detail',
					query: {
						id: _id,
						sort: true,
					}
				})
			}
		}
	};
</script>

<style scoped="scoped" lang="scss">
	.el-carousel__item h3 {
		color: #fff;
		font-size: 20px;
		opacity: 0.9;
		line-height: 150px;
		margin: 0;
		text-align: center;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #000;
	}

	.el-carousel__item:nth-child(2n + 1) {
		background-color: #000;
	}

	.info {
		margin: 10px 0 0 0;
		width: 100%;

		.OneBox {
			width: 100%;
			display: flex;
			flex-direction: row;
			padding: 0 0 0 20px;
			box-sizing: border-box;

			.hotNew {
				width: 330px;
				box-shadow: 0 0 10px #ccc;
				padding: 10px;
				box-sizing: border-box;
				margin: 0 20px 0 0;

				div {
					display: flex;
					flex-direction: row;
					align-items: center;

					img {
						width: 18px;
						height: 22px;
						margin: 0 10px 0 0;
					}

					span {
						font-size: 22px;
						font-weight: 800;
					}
				}

				ul {
					margin: 20px 0 0 0;

					li {
						font-size: 14px;
						margin: 0 0 15px 0;
						white-space: nowrap; //不换行
						overflow: hidden; //超出隐藏
						text-overflow: ellipsis; //变成...
						color: #000;

						span {
							margin: 0 10px 0 0;
							color: #999999;
						}

						.color1 {
							color: #FF2C00;
						}

						.color2 {
							color: #FF5A00;
						}

						.color3 {
							color: #FD9A15;
						}
					}

					li:hover {
						cursor: pointer;
						color: #0078F0;
					}
				}
			}

			.right {
				display: flex;
				flex-direction: column;

				.top {
					display: flex;
					flex-direction: row;

					.lunbo {
						width: 480px;
						height: 230px;

						.text {
							position: absolute;
							z-index: 1000;
							font-size: 20px !important;
							color: #fff;
							line-height: 50px;
							padding: 0 0 0 10px;
						}
					}

					.productNew {
						display: flex;
						flex-direction: column;
						width: 450px;
						margin: 0 0 0 20px;
						box-shadow: 0 0 10px #ccc;
						box-sizing: border-box;
						padding: 20px;

						.proTop {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;



							span {
								font-size: 22px;
								color: #000;
								font-weight: 800;
							}

							.more {
								font-size: 16px;
								color: #999;
							}

						}

						ul {
							li {
								margin: 0 0 10px 0;
								display: flex;
								flex-direction: row;
								padding: 13px 0;
								box-sizing: border-box;

								img {
									width: 104px;
									height: 78px;
									margin: 0 10px 0 0;
								}

								.text {
									display: flex;
									flex-direction: column;
									justify-content: space-between;
								}

								p {
									font-size: 16px;
									color: #000;
									font-weight: 800;
								}

								p:hover {
									cursor: pointer;
									color: #0078F0;
								}
							}

							.bor {
								border-bottom: 1px solid #E7EFFF;
							}
						}
					}
				}

				.bottom {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					margin: 50px 0 0 0;
				}
			}
		}

		.TwoBox {
			display: flex;
			flex-direction: row;
			padding: 0 0 0 20px;
			box-sizing: border-box;
			margin: 20px 0;

			.left {
				display: flex;
				flex-direction: column;

				.top {
					display: flex;
					flex-direction: row;

					.one {
						display: flex;
						flex-direction: column;
						width: 330px;
						box-shadow: 0 0 10px #ccc;

						.title {
							width: 330px;
							background-color: #F0F0F0;
							height: 50px;
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;
							padding: 0 10px;
							box-sizing: border-box;

							span {
								color: #000;
								font-size: 20px;
								font-weight: 800;
							}

							.more {
								color: #999;
								font-size: 16px;
							}
						}

						ul {
							width: 100%;
							box-sizing: border-box;
							padding: 10px;

							li {
								div {
									display: flex;
									flex-direction: row;
									align-items: center;

									img {
										width: 88px;
										height: 66px;
									}

									p {
										font-size: 16px;
										margin: 0 0 0 10px;
									}

									p:hover {
										cursor: pointer;
										color: #0078F0;
									}
								}

								.liP {
									font-size: 16px;
									white-space: nowrap; //不换行
									overflow: hidden; //超出隐藏
									text-overflow: ellipsis; //变成...
									margin: 15px 0 0 0;
								}

								.liP:hover {
									cursor: pointer;
									color: #0078F0;
								}
							}
						}
					}

					.two {
						display: flex;
						flex-direction: column;
						width: 480px;
						margin: 0 0 0 20px;

						.title {
							width: 100%;
							height: 50px;
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;
							padding: 0 10px;
							box-sizing: border-box;

							span {
								color: #000;
								font-size: 20px;
								font-weight: 800;
								position: relative;
							}

							.titles::after {
								content: '';
								position: absolute;
								width: 100%;
								height: 10px;
								bottom: 0;
								left: 0;
								background-color: #0078F0;
								z-index: 1;
								opacity: 0.7;
							}

							.more {
								color: #999;
								font-size: 16px;
							}
						}

						ul {
							width: 100%;
							box-sizing: border-box;
							padding: 10px;

							li {
								display: flex;
								flex-direction: row;
								margin: 0 0 15px 0;

								img {
									width: 160px;
									height: 120px;
								}

								div {
									display: flex;
									flex-direction: column;
									justify-content: space-between;
									margin: 0 0 0 10px;

									p {
										font-size: 20px;

										font-weight: 700;
									}

									p:hover {
										cursor: pointer;
										color: #0078F0;
									}

									span {}
								}
							}
						}
					}

				}

				.three {
					width: 830px;
					display: flex;
					flex-direction: column;
					margin: 0 0 0 0px;

					.title {
						width: 100%;
						height: 50px;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						padding: 0 10px;
						box-sizing: border-box;

						span {
							color: #000;
							font-size: 20px;
							font-weight: 800;
							position: relative;
						}

						.titles::after {
							content: '';
							position: absolute;
							width: 100%;
							height: 10px;
							bottom: 0;
							left: 0;
							background-color: #0078F0;
							z-index: 1;
							opacity: 0.7;
						}

						.more {
							color: #999;
							font-size: 16px;
						}
					}

					ul {
						width: 100%;
						box-sizing: border-box;
						padding: 10px;

						li {
							display: flex;
							flex-direction: row;
							margin: 0 0 30px 0;

							img {
								width: 240px;
								height: 180px;
							}

							div {
								display: flex;
								flex-direction: column;
								justify-content: space-between;
								margin: 0 0 0 10px;

								p {
									font-size: 20px;
									font-weight: 700;
								}

								.summary {
									font-size: 14px !important;
									color: #999;
									margin: 0px 0 0 0;
								}

								p:hover {
									cursor: pointer;
									color: #0078F0;
								}

								span {}
							}
						}
					}
				}
			}

			.right {
				display: flex;
				flex-direction: column;
				margin: 0 0 0 20px;

				.one {
					display: flex;
					flex-direction: column;
					box-shadow: 0 0 10px #ccc;
					width: 450px;

					.title {
						width: 100%;
						background-color: #F0F0F0;
						height: 50px;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						padding: 0 10px;
						box-sizing: border-box;

						span {
							color: #000;
							font-size: 20px;
							font-weight: 800;
						}

						.more {
							color: #999;
							font-size: 16px;
						}
					}

					ul {
						width: 100%;
						padding: 10px;
						box-sizing: border-box;

						li {
							width: 100%;
							display: flex;
							flex-direction: row;
							margin: 20px 0 30px 0;

							img {
								width: 104px;
								height: 78px;
								margin: 0 10px 0 0;
							}

							div {
								display: flex;
								flex-direction: column;
								justify-content: space-between;
							}
						}

						p:hover {
							cursor: pointer;
							color: #0078F0;
						}
					}
				}

				.two {
					display: flex;
					flex-direction: column;
					box-shadow: 0 0 10px #ccc;
					width: 450px;
					margin: 20px 0 0 0;

					.title {
						width: 100%;
						background-color: #F0F0F0;
						height: 50px;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						padding: 0 10px;
						box-sizing: border-box;

						span {
							color: #000;
							font-size: 20px;
							font-weight: 800;
						}

						.more {
							color: #999;
							font-size: 16px;
						}
					}

					ul {
						width: 100%;
						padding: 10px;
						box-sizing: border-box;

						li {
							width: 100%;
							display: flex;
							flex-direction: row;
							margin: 20px 0 30px 0;

							p {
								white-space: nowrap; //不换行
								overflow: hidden; //超出隐藏
								text-overflow: ellipsis; //变成...

								span {
									margin: 0 10px 0 0;
								}
							}

							p:hover {
								cursor: pointer;
								color: #0078F0;
							}
						}
					}
				}

				.three {
					display: flex;
					flex-direction: column;
					box-shadow: 0 0 10px #ccc;
					width: 450px;

					.title {
						width: 100%;
						background-color: #F0F0F0;
						height: 50px;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						padding: 0 10px;
						box-sizing: border-box;

						span {
							color: #000;
							font-size: 20px;
							font-weight: 800;
						}

						.more {
							color: #999;
							font-size: 16px;
						}
					}

					ul {
						width: 100%;
						padding: 10px;
						box-sizing: border-box;

						li {
							width: 100%;
							display: flex;
							flex-direction: row;
							margin: 20px 0 30px 0;

							img {
								width: 104px;
								height: 78px;
								display: inline-block;
								margin: 0 10px 0 0;
							}

							div {
								display: flex;
								flex-direction: column;
								justify-content: space-between;
							}
						}

						p:hover {
							cursor: pointer;
							color: #0078F0;
						}
					}
				}
			}
		}
	}



	.el-carousel {
		.el-carousel__item:nth-child(2n) {
			background-color: #000;
		}

		.el-carousel__item:nth-child(2n+1) {
			background-color: #000;
		}

		::v-deep .el-carousel__indicators {
			// 指示器
			left: unset;
			transform: unset;
			right: 2%;
			bottom: 45px;
		}

		::v-deep .el-carousel__button {
			// 指示器按钮
			width: 10px;
			height: 10px;
			border: none;
			border-radius: 50%;
			background-color: rgba(0, 0, 0, 0.7);
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		::v-deep .is-active .el-carousel__button {
			// 指示器激活按钮
			background: #fff;
			width: 11px;
			height: 11px;
		}
	}

	.more:hover {
		cursor: pointer;
		color: #0078F0 !important;
	}

	// .choose {
	// 	color: #39A49B !important;
	// 	position: relative;
	// }

	// .choose::after {
	// 	content: '';
	// 	width: 90%;
	// 	position: absolute;
	// 	left: 0;
	// 	right: 0;
	// 	margin: auto;
	// 	bottom: -6px;
	// 	height: 2px;
	// 	background-color: #39A49B;
	// }
</style>
